import * as React from 'react';
import { SetStateAction, useEffect } from 'react';
import { TFF } from '@tff/types';
import AlternativeFlightCards from './AlternativeFlightCards';
import { AmendBookingResponse } from '@tff/types/TFF/amend-booking-service';
import { AlternativeFlightsFooter } from './AlternativeFlightsFooter';
import BookingCard from '../ConfirmationPage/BookingCard';

interface props {
  journeys: TFF.Journey[];
  amendResponse?: AmendBookingResponse;
  setRebookStep: (value: 'Search' | 'Alternatives' | 'Validate') => void;
  rebookCloser: (fn: SetStateAction<boolean>) => void;
  handleOfferSelection: (offer: TFF.CombinationOffer) => void;
  setSelectedOfferJourneys: (value: SetStateAction<TFF.Journey[]>) => void;
}

const AlternativeFlightsPage: React.FC<props> = ({ journeys, amendResponse, setRebookStep, rebookCloser, handleOfferSelection, setSelectedOfferJourneys }) => {
  const offers: { [key: string]: TFF.OfferCombinations } = amendResponse?.flightAlternatives?.CombinationOffers!;
  const firstCombinationOfferId = Object.keys(offers)[0];
  const firstOffer = amendResponse?.flightAlternatives?.CombinationOffers?.[firstCombinationOfferId]?.Offers.sort(
    (a, b) => a.TotalAmount - b.TotalAmount,
  )[0];
  const [selectedOffer, setSelectedOffer] = React.useState<TFF.CombinationOffer | undefined>(firstOffer);

  const handleValidationClick = () => {
    if (selectedOffer) {
      handleOfferSelection(selectedOffer);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (firstCombinationOfferId && firstOffer) { // this is to find and set the Journeys of the first offer
      const outboundJourneyId = firstCombinationOfferId.split('-')[0];
      const outboundJourney = amendResponse?.flightAlternatives?.Journeys.find(
        journey => journey.Id === outboundJourneyId,
      );

      const inboundJourneyId = firstCombinationOfferId.split('-')[1];
      const inboundJourney = amendResponse?.flightAlternatives?.Journeys.find(
        journey => journey.Id === inboundJourneyId,
      );
      setSelectedOfferJourneys([
        {
          ...outboundJourney!,
          CabinClass: firstOffer.PriceClasses.Journeys[outboundJourney!.Id],
        },
        {
          ...inboundJourney!,
          CabinClass: firstOffer.PriceClasses.Journeys[inboundJourney!.Id],
        }
      ])
    }
  }, []);
  return (
    <>
      <div className="tff-original-booking">
        <BookingCard
          journeys={journeys}
          isOriginalBooking={true}
        />
      </div>
      <AlternativeFlightCards amendResponse={amendResponse} selectedOffer={selectedOffer} setSelectedOffer={setSelectedOffer} setSelectedOfferJourneys={setSelectedOfferJourneys} />
      <AlternativeFlightsFooter setRebookStep={setRebookStep} rebookCloser={rebookCloser} handleValidationClick={handleValidationClick} />
    </>
  );
};

export default AlternativeFlightsPage;
