import React from 'react';
import { TFF } from '@tff/types';
import BookingCards from './BookingCards';

interface ConfirmationPageProps {
  originalJourneys: TFF.Journey[];
  newBookingJourneys?: TFF.Journey[];
  fareDifference?: number;
}

const ConfirmationPage: React.FC<ConfirmationPageProps> = ({ originalJourneys, newBookingJourneys, fareDifference }) => {
  return (
    <div>
      <BookingCards originalJourneys={originalJourneys} newBookingJourneys={newBookingJourneys} fareDifference={fareDifference} />
      {/* Tolga TFF-3037 */}
    </div>
  );
};

export default ConfirmationPage;
